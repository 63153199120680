import {navigate} from "gatsby";
import React from "react";
import {useContext} from "react";
import {LanguageContext} from "../provider/language";
import Loading from "../components/loading";

export default () => {
  const {setLocale} = useContext(LanguageContext);
  setLocale("en");
  if (typeof window !== "undefined") {
    navigate("/", {replace: true});
    return <Loading />;
  } else {
    // TODO: Show info page with manual redirect link?
    return <Loading />;
  }
};
